$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
$hamburger-active-layer-color: #fff;

@import "~hamburgers/_sass/hamburgers/hamburgers.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

body,
html {
  min-height: 100%;
  color: #fff;
  background: #fff;
}

#root {
  overflow: hidden;
  min-height: 100%;
  color: #000;
}
.color {
  color: #ccc;
}

.aspect-\[3\/1\] {
  aspect-ratio: 3/1;
}
.aspect-\[2\/1\] {
  aspect-ratio: 2/1;
}

.aspect-\[4\/1\] {
  aspect-ratio: 4/1;
}
.aspect-\[5\/1\] {
  aspect-ratio: 5/1;
}
.aspect-\[6\/1\] {
  aspect-ratio: 6/1;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  /*font-family: "brandon-grotesque-rg", sans-serif !important;*/
  font-family: "Lato", sans-serif !important;
}

@keyframes scrollTxt {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0%, 0);
  }
}
.flag .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: transparent;
}
/*

rgba(64, 233, 255, 0.518) 0%,
    rgb(33, 112, 130, 0.518) 30%,
    rgba(0, 0, 0, 1) 100%

    */
/*
rgba(64, 169, 255, 1) 0%,
    rgba(33, 86, 130, 1) 30%,
    rgba(0, 0, 0, 1) 100%

*/
.anychart-credits {
  display: none !important;
}

.video-wrapper {
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper:hover .controls {
  transform: translateY(-0%);
  opacity: 1;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  z-index: 30;
  bottom: 5%;
  padding: 14px;
  width: 75%;
  max-width: 500px;
  flex-wrap: wrap;
  background: rgba(80, 80, 80, 0.75);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(80, 80, 80, 0.18);
  opacity: 0.1;
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  font-size: 30px;
}

input[type="range"] {
  -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  height: 4px;
  width: 60%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: white;
}

input[type="range"]::-moz-range-progress {
  background: white;
}

.velocity {
  appearance: none;
  background: none;
  color: white;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.mute-btn i {
  background-color: none;
  color: white;
  font-size: 20px;
}

.anychart-tooltip {
  background: #310b2e;
}
.anychart-tooltip span {
  color: white !important;
  font-size: 16px !important;
  line-height: 28px;
}

.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 20px;
  position: relative;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

.mouse_black {
  width: 25px;
  height: 40px;
  border: 2px solid #000;
  border-radius: 20px;
  position: relative;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}
@keyframes wheel {
  to {
    opacity: 0;
    top: 20px;
  }
}

.blob {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  margin: 10px;
  height: 30px;
  width: 30px;

  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.fadeIn {
  animation: 500ms fadeIn;
}

.fadeOut {
  animation: 500ms fadeOut;
}

.fade {
  animation: 500ms fade;
}

.hidden {
  opacity: 0;
}
@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
